import Vue from "vue";
import App from "./App.vue";
import "./components/assets/css/main.css";
import "./components/assets/css/dev.scss";
var VueTouch = require("vue-touch");

Vue.config.productionTip = false;
Vue.use(VueTouch);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
