<template>
  <div class="bg-grey-duster">
    <div class="container text-center py-12 pb-0 mx-auto">
      <div class="flex overflow-y-scroll md:overflow-hidden">
        <div class="w-full md:w-6/12 relative">
          <img
            src="../assets/bg-duster-1.svg"
            class="
              absolute
              left-6/12
              transform
              -translate-x-1/2 -translate-y-1/2
              left-1/2
              top-1/2
            "
          />
          <img
            src="../assets/dacia-duster-2.png?v=10"
            class="relative transform mx-auto block"
            style="width: 220px; width: 220px; position: relative; top: 100px"
          />
        </div>
        <div class="w-6/12 relative flex items-center">
          <div class="text-left">
            <h3 class="color-dacia text-5xl">
              <strong
                >INSTALAȚIE GPL<br />
                MONTATĂ DIN <br />FABRICĂ</strong
              >
            </h3>
            <ul class="list-tech">
              <li>
                <img src="../assets/icn_economii.png" />
                <p>O tehnologie economică...</p>
              </li>
              <li>
                <img src="../assets/icn_centura.png" />
                <p>...sigură...</p>
              </li>
              <li>
                <img src="../assets/icn_hand.png" />
                <p>... și ușor de utilizat!</p>
              </li>
            </ul>
            <!-- <p class="text-xl pt-3">pentru costuri de călătorie mai mici</p> -->
          </div>
        </div>
      </div>
      <div class="md:flex pb-10">
        <div class="w-ful md:w-6/12 relative flex items-center">
          <div class="text-right w-full pt-24">
            <h3 class="color-dacia text-5xl">
              <strong>CRUISE <br />CONTROL</strong>
            </h3>
            <p class="text-xl pt-3">pentru drumuri mai relaxante</p>
          </div>
        </div>
        <div class="w-6/12 relative pt-24">
          <img
            src="../assets/desktop-duster.png?v=10"
            class="relative transform mx-auto block"
          />
        </div>
      </div>
    </div>

    <div
      class="
        container
        text-center
        py-5
        flex
        space-x-4
        mx-auto
        items-center
        lg:justify-center
        overflow-y-scroll
        lg:overflow-hidden
      "
    >
      <div
        class="
          w-6/12
          md:w-3/12
          bg-white
          rounded-lg
          box-shadow
          flex
          px-2
          py-5
          items-center
        "
        style="min-width: 300px"
      >
        <img src="../assets/oglinzi.png" />
        <h5 class="color-dacia text-left pl-6 text-sm md:text-base">
          <strong
            >Oglinzi retrovizoare exterioare cu reglaj electric și
            degivrante</strong
          >
        </h5>
      </div>
      <div
        class="
          w-6/12
          md:w-3/12
          bg-white
          rounded-lg
          box-shadow
          flex
          px-2
          py-5
          items-center
        "
        style="min-width: 300px"
      >
        <img src="../assets/sistem-asistenta.png" />
        <h5 class="color-dacia text-left pl-6 text-sm md:text-base">
          <strong>Sistem de asistență la coborârea în pantă </strong>
        </h5>
      </div>
      <div
        class="
          w-6/12
          md:w-3/12
          bg-white
          rounded-lg
          box-shadow
          flex
          px-2
          py-5
          items-center
        "
        style="min-width: 300px"
      >
        <img src="../assets/bare-longitudinala.png" />
        <h5 class="color-dacia text-left pl-6 text-sm md:text-base">
          <strong>
            Vopsea metalizată și bare longitudinale cu aspect crom satinat
          </strong>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCta",
  props: {
    msg: String,
  },
};
</script>
