<template>
  <div class="block container text-center py-12 pt-20 mx-auto">
    <h3 class="text-2xl uppercase">
      <strong>
        FOLOSEȘTE MAȘINA VECHE CA AVANS PENTRU <br /><span class="color-dacia"
          >DACIA DUSTER</span
        >
        ȘI BENEFICIEZI DE O REDUCERE SUPLIMENTARĂ <br />ÎN VALOARE DE
        <span class="color-dacia">600 de euro</span>,
      </strong>
      cumulabilă cu oferta <br />prin Programul RABLA și Dacia Avantaj.
    </h3>

    <div
      class="
        list-steps
        flex
        space-x-4
        mx-auto
        items-start
        lg:justify-center
        pb-6
        lg:pb-0
        pt-16
        w-full
        overflow-x-auto
        lg:overflow-hidden
      "
    >
      <div class="step md:w-3/12">
        <span class="">1</span>
        <p class="color-dacia text-base md:pt-10">
          <strong
            >Aduci mașina ta veche<br />
            pentru a fi evaluată <strong>gratuit</strong> <br />
            de către experții noștri.</strong
          >
        </p>
      </div>
      <div class="step md:w-3/12">
        <span>2</span>
        <p class="color-dacia text-base md:pt-10">
          <strong>
            O predai ca avans pentru<br />
            Dacia Duster Seria Limitată<br />
            ECO-G 100 și beneficiezi de <br />
            reducerea de 600 EUR.</strong
          >
        </p>
      </div>
      <div class="step md:w-3/12">
        <span>3</span>
        <p class="color-dacia text-base md:pt-10">
          <strong>
            Tu alegi cum plătești:<br />
            integral sau prin intermediul<br />
            soluțiilor de finanțare<br />
            oferite de Dacia Finance.
          </strong>
        </p>
      </div>
    </div>
    <div class="container w-11/12 mx-auto text-center pt-6">
      <p class="text-gray-500 text-xs">
        Pentru mai multe detalii, consultă
        <a href="regulament.pdf" target="_blank" class="underline"
          >regulamentul promoției</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNumber",
  props: {
    msg: String,
  },
};
</script>
