<template>
  <div class="container text-center py-12 mx-auto">
    <h3 class="text-5xl hidden md:static">
      <strong>Dacia Duster Seria Limitată <br />BlueLine ECO-G 100</strong>
    </h3>
    <p class="hidden md:static text-xl leading-7 pt-6">
      îți alimentează călătoriile cu tot ce ai nevoie: confort, plăcerea<br />
      de a conduce și mai puține griji.
    </p>
  </div>
</template>

<script>
export default {
  name: "AppCta",
  props: {
    msg: String,
  },
};
</script>
