/* eslint-disable no-unused-vars */
<template>
  <div id="app">
    <AppHeader />
    <div class="block md:hidden">
      <MobileScene />
    </div>
    <div class="hidden md:block">
      <div
        :class="`${
          hasStarted
            ? 'transition duration-1000 ease-in  opacity-100'
            : 'transition duration-1000 ease-in  opacity-0 pointer-events-none'
        }`"
      >
        <div class="fixed -top-10 left-0 bg-white z-50">
          <video
            @ended="onEnd()"
            class="w-full object-cover"
            autoplay
            muted
            playsinline
          >
            <source src="./assets/Desktop.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div
        :class="`${
          !hasStarted
            ? 'transition duration-1000 ease-in  opacity-100'
            : 'transition duration-1000 ease-in  opacity-0 pointer-events-none'
        }`"
      >
        <AppSlider />
        <div v-if="hasfold">
          <AppNumber />
        </div>
        <AppCta />
        <AppCarOptions />
        <AppSteps />
        <Slider />
        <Footer />
      </div>
    </div>
    <footer>
      <div class="container-fixed">
        <div class="container-table isfixed">
          <div class="table-element element-middle element-left">
            <p class="text-white">© Dacia 2021</p>
          </div>

          <div class="table-element element-middle element-right">
            <a href="https://www.facebook.com/DaciaRomania/" target="_blank"
              ><img src="./assets/facebook-icon.png" alt="Facebook Dacia"
            /></a>

            <a
              href="https://www.youtube.com/user/OfficialDaciaRomania"
              target="_blank"
              ><img src="./assets/youtube-icon.png" alt="Youtube Dacia"
            /></a>

            <a href="https://www.instagram.com/dacia_romania/" target="_blank"
              ><img src="./assets/instagram-icon.png" alt="Instagram Dacia"
            /></a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppSlider from "./components/AppSlider.vue";
import AppCta from "./components/AppCta.vue";
import AppNumber from "./components/AppNumber.vue";
import AppCarOptions from "./components/AppCarOptions.vue";
import AppSteps from "./components/AppSteps.vue";
import Slider from "./components/Slider.vue";
import Footer from "./components/Footer.vue";
import MobileScene from "./components/MobileScene.vue";

export default {
  created() {
    // console.log(location.hash);
    if (location.hash === "#fold") {
      this.hasfold = false;
    }
    setTimeout(() => {
      // this.hasStarted = false;
    }, 4500);
  },
  data() {
    return {
      top: 450,
      hasfold: window.hasfold,
      steps: 0,
      containerTop: 0,
      hasStarted: true,
    };
  },
  methods: {
    onEnd() {
      this.hasStarted = false;
    },
  },
  name: "App",
  components: {
    AppHeader,
    AppSlider,
    AppCta,
    AppNumber,
    MobileScene,
    AppCarOptions,
    AppSteps,
    Slider,
    Footer,
  },
};
</script>

<style scoped>
footer {
  background: #10314c;
  padding: 25px 0;
}
.container-fixed {
  max-width: 1500px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.container-table {
  display: table;
  width: 100%;
}

.container-table.isfixed {
  table-layout: fixed;
}
.element-middle {
  vertical-align: middle;
}

.element-left {
  text-align: left;
}
.table-element {
  display: table-cell;
}
.element-right {
  text-align: right;
}
footer p {
  text-align: left;
}
footer .element-right a {
  padding: 0 5px;
  color: #fff;
  display: inline-block;
}
</style>