<template>
  <div class="container text-center py-12 mx-auto">
    <h3 class="text-5xl">
      <strong> Vino cu un pas mai aproape de Duster!</strong>
    </h3>
    <div
      class="flex space-x-4 mx-auto items-center justify-center pt-16"
      style="min-width: 250px"
    >
      <div class="border-dacia w-3/12 rounded-lg flex h-32 items-center">
        <h4 class="text-center w-full text-2xl">
          <strong class="color-dacia">Livrare rapidă din stoc</strong>
        </h4>
      </div>
      <div
        class="border-dacia w-3/12 rounded-lg flex h-32 items-center"
        style="min-width: 250px"
      >
        <h4 class="text-center w-full text-2xl">
          <strong class="color-dacia">Cea mai bună ofertă de preț </strong>
        </h4>
      </div>
      <div
        class="border-dacia w-3/12 rounded-lg h-32 flex items-center"
        style="min-width: 250px"
      >
        <div class="w-full">
          <h2 class="text-orange text-3xl">
            <strong>DE LA 13.540EUR*</strong>
          </h2>
          <p class="text-base">(TVA inclus)</p>
        </div>
      </div>
    </div>

    <h3 class="text-base pt-16">
      Dacia Duster Seria Limitată BlueLine ECO-G 100!
    </h3>
    <p class="text-3xl uppercase">
      <strong
        >de la 81 EUR/lună** TVA inclus,
        <span class="color-dacia">
          <!-- -->
          PRIN PROGRAMUL<br />
          RABLA 2021 ȘI DACIA AVANTAJ</span
        ></strong
      >
    </p>
    <p class="text-xl pt-2">
      cu opțiunea de amânare la plata ratelor de până la 6 luni
    </p>
    <div class="pt-2 w-2/12 mx-auto" style="min-width: 300px">
      <p><b>Include:</b></p>
      <ul style="list-style-position: inside" class="text-sm">
        <li>&bull; &nbsp;&nbsp;Garanție extinsă (până la 5 ani)</li>
        <li>&bull; &nbsp;&nbsp;Revizii incluse (până la 5 ani)</li>
        <li>&bull; &nbsp;&nbsp;Asistență rutieră (până la 5 ani)</li>
        <li>&bull; &nbsp;&nbsp;Dobândă fixă / 60luni<br /></li>
      </ul>
    </div>

    <div class="flex space-x-4 mx-auto items-center justify-center pt-16">
      <a
        href="https://vanzari.dacia.ro/exploreaza-gama/duster/serie-limitata-blueline.html?utm_source=lpduster"
        target="_blank"
        @click="clickbtn1()"
        class="btn"
        >Rezervă online!</a
      >
      <a
        href="https://www.dacia.ro/contact/alege-model-rabla/solicita-oferta-rabla.html?modelAdminId=duster-hjd-ph1"
        target="_blank"
        @click="clickbtn2()"
        class="btn"
        style="font-size: 15px"
        >Cere ofertă personalizată!</a
      >
    </div>
    <div class="container text-left pt-6">
      <p class="text-gray-500 text-xs">
        *Ofertă supusă unor condiţii. Mașina din imagine este cu titlu de
        prezentare. Prețul include TVA.
        <br />
        <br />
        Ofertă destinată persoanelor fizice, supusă unor termene si condiții,
        valabilă în perioada 01 - 30 iunie 2021, prin Dacia Finance, denumire
        comerciala sub care operează RCI Leasing Romania IFN SA.
        <br />
        <br />

        Exemplu de calcul reprezentativ pentru un autoturism Dacia Noul Duster
        SL BlueLine ECO-G 100, cu servicii incluse, în valoare de 72.241 lei, cu
        50% avans, 395 lei rată de credit lunara (nu include RCA și CASCO).
        Dobândă fixă (6,50%). Costul total al creditului include: comision de
        analiza dosar 270 lei, comision lunar de administrare 55 lei, asigurare
        Trafic Accident 14 lei/lună, cost înscriere contract în RNPM 87,6 lei,
        valoare totală a creditului 36.121 lei, DAE 9,65%, durata 60 de luni,
        valoare plată reziduală 27.113 lei, valoare totală plătibilă 50.823 lei.
        Curs de calcul estimat 1 euro = 4,9 lei.
        <br />
        <br />

        Produsul include valoarea contractului tip garanție extinsă Dacia
        Extenso valabil 24 luni sau 100.000 km (prima limită atinsă) și valoarea
        contractului de întreținere Easy Service, valabil 5 ani de la livrare
        sau 100.000 km (prima limită atinsă), cu asistenta rutiera cu
        autovehicul de înlocuire in limita a 3 zile. Contractul de intretinere
        Easy Service include operatiunile de intretinere (revizii periodice)
        mentionate in carnetul de intretinere al autovehiculului.
        <br />
        <br />
        <br />
        <br />
        <br />

        Detalii în
        <a
          href="https://www.dacia.ro/reteaua-dacia.html"
          class="underline"
          target="_blank"
          >rețeaua de agenți Dacia.</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickbtn1() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Rezervă online!",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
    clickbtn2() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Cere o ofertă personalizată",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
  },
  name: "AppSteps",
  props: {
    msg: String,
  },
};
</script>
