<template>
  <div>
    <div
      class="
        full-container
        bg-dacia-blue-desktop
        app-slider
        pt-10
        md:pt-0
        justify-end
        md:flex
      "
      style="min-height: 520px"
    >
      <img
        src="../assets/dacia-duster.png"
        alt="Dacia Duster"
        class="w-full md:w-6/12 hidden md:block"
      />
      <div
        class="
          md:absolute
          container
          md:left-1/2
          md:transform
          md:-translate-x-1/2
          text-left
          flex
          items-center
          md:h-full
        "
      >
        <div class="w-full md:w-5/12 text-center md:text-left">
          <h1 class="text-2xl md:text-4xl">
            EXPLOREAZĂ VARA CU<br />
            <strong class="text-2lx md:text-5xl"
              >DACIA DUSTER<br />
              SERIA LIMITATĂ<br />
              BLUELINE ECO-G 100!</strong
            >
          </h1>
          <div class="block md:hidden arrow-slide"></div>
          <p
            class="
              text-white
              pt-8
              text-base
              md:leading-9
              px-2
              md:px-0
              w-full
              md:w-5/6
              text-center
              md:text-left
            "
          >
            Un SUV creat pentru aventură: mai practic, mai prietenos cu mediul,
            cu instalație GPL montată din fabrică! <br />Acum de la
            <strong> 13.540 EUR*</strong> (TVA inclus) prin Programul RABLA 2021
            și Dacia Avantaj
          </p>
        </div>
      </div>
    </div>
    <div
      class="hidden md:flex space-x-4 mx-auto items-center justify-center pt-16"
    >
      <a
        href="https://vanzari.dacia.ro/exploreaza-gama/duster/serie-limitata-blueline.html?utm_source=lpduster"
        @click="clickbtn1()"
        target="_blank"
        class="btn"
        >Rezervă online!</a
      >
      <a
        href="https://www.dacia.ro/contact/alege-model-rabla/solicita-oferta-rabla.html?modelAdminId=duster-hjd-ph1"
        @click="clickbtn2()"
        target="_blank"
        class="btn bnt-small"
        >Cere ofertă personalizată!</a
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickbtn1() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Rezervă online!",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
    clickbtn2() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Cere o ofertă personalizată",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
  },
  name: "AppSlider",
  props: {
    msg: String,
  },
};
</script>
