<template>
  <div class="header">
    <div
      class="container flex py-5 md:py-11 px-4 justify-between mx-auto relative z-50"
    >
      <a href="/"><img src="../assets/logo.svg" class="logo" /></a>
      <h3 class="text-sm md:text-base">DUSTER SERIA LIMITATĂ BLUELINE</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    msg: String,
  },
};
</script>
