<template>
  <div
    class="flex overflow-x-auto"
    style="align-items: center; align-items: center"
  >
    <img
      src="../assets/mobile-slider-1.png"
      style="width: 300vw; max-width: 300vw"
    />
    <img
      src="../assets/mobile-slider-2.png"
      style="width: 300vw; max-width: 300vw"
    />
    <img
      src="../assets/mobile-slider-3.png"
      style="width: 300vw; max-width: 300vw"
    />
  </div>
</template>

<script>
export default {
  name: "AppSteps",

  props: {
    msg: String,
  },
};
</script>
