<template>
  <div class="">
    <carousel :items="1" :nav="true">
      <img src="../assets/ornament.png" class="w-full" />
      <img src="../assets/img2.png" class="w-full" />
      <img src="../assets/img3.png" class="w-full" />
    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "AppSteps",
  components: { carousel },

  props: {
    msg: String,
  },
};
</script>
