<template>
  <div class="container text-center py-12 mx-auto">
    <h3 class="text-3xl pt-16">
      <strong>
        Dacia Duster Seria Limitată <br />
        BlueLine ECO-G 100
      </strong>
    </h3>
    <div class="flex space-x-4 mx-auto items-center justify-center pt-16">
      <a
        href="https://vanzari.dacia.ro/exploreaza-gama/duster/serie-limitata-blueline.html?utm_source=lpduster"
        @click="clickbtn1()"
        target="_blank"
        class="btn"
        >Rezervă online!</a
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickbtn1() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Rezervă online!",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
  },
  name: "AppSteps",
  props: {
    msg: String,
  },
};
</script>
