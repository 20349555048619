<template>
  <div class="w-full">
    <div v-if="loader">
      <div class="loader">
        <img src="/loading.svg" />
      </div>
    </div>
    <div
      :class="`${
        hasStarted
          ? 'transition ease-in  opacity-100'
          : 'transition ease-in  opacity-0 pointer-events-none'
      }`"
    >
      <div class="fixed -top-10 left-0 bg-white" style="z-index: 999">
        <video
          @ended="onEnd()"
          id="thevideo"
          class="w-full object-cover"
          autoplay
          muted
          playsinline
        >
          <source src="../assets/Mobile.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div
      :style="style"
      :class="`${
        !hasStarted
          ? 'transition ease-in  opacity-100'
          : 'transition ease-in  opacity-0 pointer-events-none'
      }`"
    >
      <div class="overlay-b" v-if="thisCanScroll" id="the"></div>

      <v-touch ref="swiper" @swipe="handleSwipe">
        <div class="bg-blue-mobile pt-9" ref="phoneBg" id="scene2">
          <div
            :class="`${thisCanScroll ? 'pointer-events-none' : ''}`"
            id="main1"
          >
            <div>
              <AppSlider />
              <div
                class="
                  container
                  text-center
                  py-12
                  mx-auto
                  w-9/12
                  absolute
                  opacity-0
                  top-0
                "
                id="appcta"
              >
                <p class="block text-base pt-6 px02">
                  Dacia Duster Seria Limitată BlueLine ECO-G 100 îți alimentează
                  călătoriile cu tot ce ai nevoie: confort, plăcerea de a
                  conduce și mai puține griji.
                </p>
              </div>
            </div>
            <div
              class="block md:flex absolute opacity-0 bottom-10 z-10"
              id="options"
            >
              <div class="w-full relative items-center">
                <div class="text-center px-4">
                  <h3 class="color-dacia text-4xl">
                    <strong>INSTALAȚIE GPL MONTATĂ DIN FABRICĂ</strong>
                  </h3>
                  <ul class="list-tech">
                    <li>
                      <img src="../assets/icn_economii.png" />
                      <p>O tehnologie economică...</p>
                    </li>
                    <li>
                      <img src="../assets/icn_centura.png" />
                      <p>...sigură...</p>
                    </li>
                    <li>
                      <img src="../assets/icn_hand.png" />
                      <p>... și ușor de utilizat!</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <img
              src="../assets/car-mobile.png"
              class="fixed-position"
              id="scene1"
            />
            <div id="scene3" class="fixed-position opacity-0" style="">
              <img src="../assets/mobile-pic-2.png" />
            </div>
          </div>
        </div>
      </v-touch>
      <div id="main2" class="h-full">
        <div
          id="show3"
          v-if="positionScroll < 1600 && hasanim"
          class="
            flex
            fixed
            bottom-2
            space-x-4
            mx-auto
            items-center
            justify-center
            pt-16
            w-full
            z-50
          "
          style="width: 100vw; z-index: 999999"
        >
          <a
            href="https://www.dacia.ro/contact/alege-model-rabla/solicita-oferta-rabla.html?modelAdminId=duster-hjd-ph1"
            @click="clickbtn2()"
            target="_blank"
            class="btn border border-white"
            >Cere ofertă personalizată!</a
          >
        </div>
        <div v-if="hasfold">
          <AppNumber />
        </div>

        <div class="bg-dacia-blue h-full pt-14 pb-16">
          <div class="block">
            <div class="w-full relative">
              <img
                src="../assets/dacia-duster-3.png"
                class="relative transform"
              />
            </div>
            <div class="w-ful md:w-6/12 relative flex items-center px-4">
              <div class="text-left w-full">
                <h3 class="text-white text-4xl">
                  <strong>CRUISE <br />CONTROL</strong>
                </h3>
                <p class="text-base text-white pt-3">
                  pentru drumuri mai relaxante
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main3" class="h-full w-full overflow-hidden">
        <SliderMobile />
      </div>

      <div id="main4" class="h-full w-full">
        <div class="container text-center py-12 mx-auto px-2">
          <h3 class="text-3xl">
            <strong>Vino cu un pas mai aproape de Duster!</strong>
          </h3>

          <p class="text-base uppercase pt-4">
            <strong
              >de la 81 EUR/lună** TVA inclus,
              <span class="color-dacia">
                <!-- -->
                PRIN PROGRAMUL<br />
                RABLA 2021 ȘI DACIA AVANTAJ</span
              ></strong
            >
          </p>
          <p class="text-xl pt-2">
            cu opțiunea de amânare la plata ratelor de până la 6 luni
          </p>
          <div class="pt-2 w-7/12 mx-auto">
            <p><b>Include:</b></p>
            <ul style="list-style-position: inside" class="text-sm">
              <li>&bull; &nbsp;&nbsp;Garanție extinsă (până la 5 ani)</li>
              <li>&bull; &nbsp;&nbsp;Revizii incluse (până la 5 ani)</li>
              <li>&bull; &nbsp;&nbsp;Asistență rutieră (până la 5 ani)</li>
              <li>&bull; &nbsp;&nbsp;Dobândă fixă / 60luni<br /></li>
            </ul>
          </div>
        </div>

        <div
          class="
            container
            text-center
            py-6
            pt-2
            flex
            space-x-4
            mx-auto
            w-full
            overflow-hidden
          "
        >
          <div class="flex overflow-x-auto pb-6">
            <div
              class="
                w-90vw
                bg-white
                rounded-lg
                box-shadow
                flex
                px-2
                py-5
                items-center
              "
            >
              <img src="../assets/oglinzi.png" />
              <h5 class="color-dacia text-left pl-6">
                <strong
                  >Oglinzi retrovizoare exterioare cu reglaj electric și
                  degivrante</strong
                >
              </h5>
            </div>
            <div
              class="
                w-90vw
                bg-white
                rounded-lg
                box-shadow
                flex
                px-2
                py-5
                items-center
              "
            >
              <img src="../assets/sistem-asistenta.png" />
              <h5 class="color-dacia text-left pl-6">
                <strong>Sistem de asistență la coborârea în pantă </strong>
              </h5>
            </div>
            <div
              class="
                w-90vw
                bg-white
                rounded-lg
                box-shadow
                flex
                px-2
                py-5
                items-center
              "
            >
              <img src="../assets/bare-longitudinala.png" />
              <h5 class="color-dacia text-left pl-6">
                <strong>
                  Vopsea metalizată și bare longitudinale cu aspect crom satinat
                </strong>
              </h5>
            </div>
          </div>
        </div>
        <div class="container text-center py-4 mx-auto space-y-4">
          <h4 class="text-center w-full text-xl">
            <strong class="text-blacl">Livrare rapidă din stoc</strong>
          </h4>
          <h4 class="text-center w-full text-xl">
            <strong class="text-blacl">Cea mai bună ofertă de preț </strong>
          </h4>
          <div
            class="
              border-dacia
              w-9/12
              mx-auto
              rounded-lg
              h-28
              flex
              items-center
            "
            style="border-color: #ff5c00"
          >
            <div class="w-full">
              <h2 class="text-orange text-4xl">
                <strong> DE LA 13.540EUR*</strong>
              </h2>
              <p class="text-base">(TVA inclus)</p>
            </div>
          </div>

          <div class="flex space-x-4 mx-auto items-center justify-center pt-8">
            <a
              href="https://vanzari.dacia.ro/exploreaza-gama/duster/serie-limitata-blueline.html?utm_medium=lpduster"
              @click="clickbtn1()"
              target="_blank"
              class="btn"
              style="font-size: 15px"
              >Rezervă online!</a
            >
          </div>

          <div class="container text-left pt-6 px-4">
            <p class="text-gray-500 text-xs">
              *Ofertă supusă unor condiţii. Mașina din imagine este cu titlu de
              prezentare. Prețul include TVA.
            </p>
            <a
              @click="showMOre()"
              v-if="showMore == 0"
              class="text-sm underline"
              >Vezi mai mult</a
            >
            <p class="text-gray-500 text-xs" v-if="showMore == 1">
              <br />
              <br />

              <br />
              <br />
              Ofertă destinată persoanelor fizice, supusă unor termene si
              condiții, valabilă în perioada 01 - 30 iunie 2021, prin Dacia
              Finance, denumire comerciala sub care operează RCI Leasing Romania
              IFN SA.
              <br />
              <br />

              Exemplu de calcul reprezentativ pentru un autoturism Dacia Noul
              Duster SL BlueLine ECO-G 100, cu servicii incluse, în valoare de
              72.241 lei, cu 50% avans, 395 lei rată de credit lunara (nu
              include RCA și CASCO). Dobândă fixă (6,50%). Costul total al
              creditului include: comision de analiza dosar 270 lei, comision
              lunar de administrare 55 lei, asigurare Trafic Accident 14
              lei/lună, cost înscriere contract în RNPM 87,6 lei, valoare totală
              a creditului 36.121 lei, DAE 9,65%, durata 60 de luni, valoare
              plată reziduală 27.113 lei, valoare totală plătibilă 50.823 lei.
              Curs de calcul estimat 1 euro = 4,9 lei.
              <br />
              <br />

              Produsul include valoarea contractului tip garanție extinsă Dacia
              Extenso valabil 24 luni sau 100.000 km (prima limită atinsă) și
              valoarea contractului de întreținere Easy Service, valabil 5 ani
              de la livrare sau 100.000 km (prima limită atinsă), cu asistenta
              rutiera cu autovehicul de înlocuire in limita a 3 zile. Contractul
              de intretinere Easy Service include operatiunile de intretinere
              (revizii periodice) mentionate in carnetul de intretinere al
              autovehiculului.
              <br />
              <br />
              <br />

              Detalii în
              <a
                href="https://www.dacia.ro/reteaua-dacia.html"
                class="underline"
                target="_blank"
                >rețeaua de agenți Dacia.</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSlider from "./AppSlider.vue";
import SliderMobile from "../components/SliderMobile.vue";
import AppNumber from "./AppNumber";

import { TweenMax } from "gsap";
import scrollmagic from "scrollmagic";
export default {
  data() {
    return {
      steps: 0,
      showMore: 0,
      position: 0,
      hasStarted: true,
      loader: true,
      positionScroll: 0,
      hasfold: true,
      thisCanScroll: false,
      hasanim: false,
      style: "overflow:hidden; height:100vh; position:relative",
    };
  },
  created() {
    if (location.hash === "#fold") {
      this.hasfold = false;
    }

    setTimeout(() => {
      this.loader = false;
      document.getElementById("thevideo").play();
    }, 1000);
    window.addEventListener("scroll", this.handleScroll);
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { AppSlider, SliderMobile, AppNumber },
  methods: {
    handleScroll() {
      this.positionScroll = window.scrollY;
    },
    clickbtn1() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Rezervă online!",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },
    clickbtn2() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "Dacia Duster SL Blueline",
        eventAction: "Cere o ofertă personalizată",
        eventLabel: "Landing page Dacia Duster SL Blueline",
      });
    },

    showMOre() {
      this.showMore = 1;
    },
    onEnd() {
      this.hasStarted = false;
    },

    firstAnimation() {
      const controller = new scrollmagic.Controller({
        container: "#app",
        globalSceneOptions: {
          triggerHook: "onLeave",
        },
      });
      const abracadabra = TweenMax.fromTo(
        "#scene1",
        1,
        { top: 450, left: 0, rotation: 0 },
        { top: 150, rotation: 0 }
      );
      const abracadabra2 = TweenMax.fromTo(
        "#scene2",
        1,
        { background: "#0b2bfb" },
        { background: "#fff", height: "112vh" }
      );
      const appcta = TweenMax.fromTo(
        "#appcta",
        1,
        { opacity: "0" },
        { opacity: "1", delay: 1 }
      );
      const abracadabra3 = TweenMax.fromTo(
        "#scene1",
        1,
        { opacity: 1 },
        { opacity: 0, delay: 1 }
      );
      const show3 = TweenMax.fromTo(
        "#show3",
        1,
        { opacity: 0 },
        { opacity: 1, delay: 1 }
      );

      const caroptions = TweenMax.fromTo(
        "#scene3",
        1,
        { top: 170, rotation: 0, opacity: 0 },
        { opacity: 1, delay: 1 }
      );
      new scrollmagic.Scene({
        offset: 20,
        duration: 180,
      });
      const options = TweenMax.fromTo(
        "#options",
        1,
        { opacity: 0 },
        { opacity: 1, delay: 1 }
      );
      new scrollmagic.Scene({
        offset: 20,
        duration: 180,
      })
        .setTween([
          abracadabra,
          abracadabra2,
          appcta,
          abracadabra3,
          caroptions,
          show3,
          options,
        ])

        .on("end", () => {
          console.log("xxx");
          appcta.progress(1).progress(0);
          appcta.play();
        })
        .addTo(controller);
      this.style = "";
      setTimeout(() => {
        this.hasanim = 1;
        this.thisCanScroll = 1;
      }, 1000);
    },
    movingHandler(e) {
      console.log(e);
    },
    handleSwipe(e) {
      console.log("e", e);
      //   console.log(e.direction, this.steps);
      //   if (e.direction == 2) {
      //     if (this.steps == 4) {
      //       let p = this.position - 90;
      //       if (p < -190) p = -190;
      //       console.log(p);

      //       this.position = p;
      //     }
      //   }
      //   if (e.direction == 4) {
      //     if (this.steps == 4) {
      //       let p = this.position + 90;
      //       if (p > 0) p = 0;
      //       this.position = p;
      //     }
      //   }

      //   if (e.direction === 16) {
      //     if (this.steps == 2) {
      //       this.seccondAnimationBack();
      //       this.steps = 1;
      //     }
      //     if (this.steps == 3) {
      //       this.thirdAnimationBack();
      //       this.steps = 2;
      //     }

      //     if (this.steps == 4) {
      //       this.fourthAnimationBack();
      //       this.steps = 3;
      //     }
      //   }
      if (e.direction === 8) {
        // if (this.steps == 3) {
        //   this.fourthAnimation();
        //   this.steps = 4;
        // }
        // if (this.steps == 2) {
        //   this.thirdAnimation();
        //   this.steps = 3;
        // }
        // if (this.steps == 1) {
        //   this.seccondAnimation();
        //   this.steps = 2;
        // }
        if (this.steps == 0) {
          this.firstAnimation();
          this.steps = 1;
        }
      }
    },
  },
  name: "AppSteps",

  props: {
    msg: String,
  },
};
</script>
